label {
    color: white;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(1, 17, 58);
    height: 300px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    border: 2px solid white;
    margin: 0 auto;
}

.login-header {
    font-family: 'Oleo Script', cursive;
    font-size: 40px;
    text-align: center;
    color: white;
}

.invalid-login {
    color: white;
    text-align: center;
}