#landing-hero {
    background: url(../../images/restaurant-red-blur.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 100%;
}

.landing_sections {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
}

.info {
    padding: 25px 50px;
    min-height: 375px;
    background: rgb(22, 40, 89);
    width: 100%;
    justify-content: center;
}

.white {
    background: white;
    display: flex;
    flex-direction: column;
}

.white > p {
    color: rgb(192, 14, 5);
}

.white-title {
    color: white;
}

.blue-title {
    color: rgb(1, 17, 58);
}

.red-title {
    color: rgb(192, 14, 5);
}

.red {
    background: rgb(192, 14, 5);
    display: flex;
    flex-direction: column;
}

.blue {
    background: url(../../images/navy.jpg) no-repeat center center fixed;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.blue > p {
    color: white;
}

.red > p {
    color: rgb(1, 17, 58);
}

.info > h3 {
    font-size: 45px;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.info > p {
    font-size: 22px;
    max-width: 500px;
    font-family: 'Montserrat', sans-serif;
}

.tagline {
    font-family: 'Oleo Script', cursive;
    font-size: 110px;
    margin-bottom: 0px;
    line-height: 1.2;
    color: white;
}

.sub-tagline {
    font-family: 'Oleo Script', cursive;
    font-size: 40px;
    margin-top: 0px;
    color: rgb(1, 17, 58);
    max-width: 275px;
    text-align: center;
    background: white;
    border-radius: 10px;
}

.landing-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0px;
    padding-left: 30px;
    height: 350px;
    margin: 80px 20px;
}

.not-logged-in {
    display: flex;
    flex-direction: row;
}

.demo {
    align-items: center;
}

.demo > p {
    font-size: 16px;
    text-align: center;
}

.credentials {
    list-style: none;
    font-weight: 600;
    color: rgb(1, 17, 58);
}

@media screen and (max-width: 800px) {
    .tagline {
        font-size: 80px;
    }
    
    .landing_sections {
        display: flex;
        flex-direction: column;
    }

    .info {
        height: initial;
    }

    .landing-header {
        margin-top: 50px;
    }

    .info > p {
        font-size: 24px;
    }

    h3 {
        font-size: 30px;
    }
}

@media screen and (max-width: 415px) {
    .Header {
        flex-direction: column;
        height: 200px;
        justify-content: center;
    }

    .tagline {
        font-size: 60px;
    }

    .sub-tagline {
        font-size: 30px;
    }

    .justify-start {
        margin-left: initial;
        margin-right: initial;
    }

    .not-logged-in {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .not-logged-in > a {
        margin: 0;
    }

    .info > h3 {
        font-size: 30px;
    }

    .info > p {
        font-size: 16px;
    }
}

