.place-name {
    font-size: 32px;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    transition: all .2s linear;
}

.place-name:hover {
    font-size: 34px;
    color: rgb(192, 14, 5);
}

.place-type {
    font-size: 18px;
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    margin: 10px 0px;
    color: white;
}

.place-section {
    border: 2px solid white;
    border-radius: 10px;
    max-width: 600px;
    width: 45%;
    min-width: 230px;
    background: rgb(1, 17, 58);
    font-family: 'Montserrat', sans-serif;
    margin: 40px auto 10px auto;
}

.place-controls {
    width: 80px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

#items-ordered {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.item-header {
    margin-bottom: 5px;
    text-decoration: underline;
}

.items-list {
    margin: 0;
    margin-bottom: 15px;
    padding-left: 0;
}

.items-list > li {
    list-style: none;
    font-size: 16px;
}

section {
    padding: 15px;
}

#notes {
    width: 80%;
    margin: 10px auto;
    color: white;
}

.notes-container {
    margin: 20px 0;
}

.notes-title {
    margin-bottom: 5px;
}

.notes-content {
    margin: 0;
    font-size: 16px;
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px;
}

button:hover {
   cursor: pointer;
}

.edit-delete {
    width: 75px;
    height: 25px;
    border-radius: 5px;
    text-align: center;
    background: white;
    border: 1px solid white;
    margin: 5px;
    font-size: 16px;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: rgb(1, 17, 58);
    margin: 5px auto;
    transition-property: background, color;
    transition-duration: .2s;
    transition-timing-function: linear;
}

.edit-delete:hover {
    background: rgb(1, 17, 58);
    color: white;
}

.delete-button {
    background: none;
    border: none;
}

.edit-icon {
    color: white;
    font-size: 22px;
}

.delete-icon {
    color: white;
    font-size: 22px;
}

.edit-icon:hover, .delete-icon:hover {
    color: red;
}

.hh {
    margin: 0px;
}

#hh-box {
    margin-bottom: 15px;
    color: white;
}
