.remove-item {
    /* color: black; */
    margin-left: 5px;
    background: none;
    border: none;
}

.remove-item-icon {
    color: white;
    font-size: 16px;
}