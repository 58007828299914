* {
    box-sizing: border-box;
  }

html {
  height: 100%;
  background: url(../../images/orange-red.jpg) no-repeat center center fixed;
}

body {
  height: inherit;
  font-size: 20px;
  /* color: white; */
  line-height: 1.5;
  background-size: cover;
}

#root {
  height: inherit;
}

.App {
  height: inherit;
}

.App_main {
  height: inherit;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

label {
  color: white;
  margin-right: 5px;
}  

input, textarea {
  color: black;
}

.go-back {
  color: white;
  width: 75px;
  background: rgb(1, 17, 58);
  font-size: 22px;
  text-align: center;
  margin: 10px auto;
  border: 1px solid white;
  border-radius: 5px;
  display: block;
  transition-property: background, color;
  transition-duration: .2s;
  transition-timing-function: linear;
}

.go-back:hover {
  color: rgb(1, 17, 58);
  background: white;
}

@media screen and (max-width: 415px) {
  .App_main {
    margin-top: 180px;
  }
}