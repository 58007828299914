.notes {
    width: 100%;
}

.place-notes {
    width: 100%;
    border-radius: 7px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.add-place {
    font-family: 'Montserrat', sans-serif;
    background: rgb(1, 17, 58);
    padding: 30px;
    border-radius: 10px;
}

.add-place-title {
    font-family: 'Oleo Script', cursive;
    font-size: 40px;
    color: white;
}

.add-place-header {
    padding: 0px;
}

.add-place-form {
    display: flex;
    flex-direction: column;
}

.save-place {
    align-self: center;
    margin-top: 20px;
    color: rgb(1, 17, 58);
    width: 75px;
    height: 25px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    background: white;
    transition-property: background, color;
    transition-duration: .2s;
    transition-timing-function: linear;
}

.save-place:hover {
    background: rgb(1, 17, 58);
    color: white;
}

.place_name {
    height: 30px;
    font-size: 18px;
    border-radius: 7px;
    font-family: 'Montserrat', sans-serif;
}

.add-item {
    width: 90px;
}

label {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.items {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.item {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

@media screen and (max-width: 400px) {
    .add-place {
        max-width: 350px;
    }
}