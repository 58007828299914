@import url(https://fonts.googleapis.com/css?family=Oleo+Script|Montserrat:400,600&display=swap);
.Header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    background: rgb(1, 17, 58);
    border-bottom: 5px solid white;
    height: 120px;
}

.justify-start {
    margin-right: auto;
    margin-left: 10px;
}

#register, #login {
    padding: 5px;
}

a {
    text-decoration: none;
    color: white;
}

.nav-link {
    font-family: 'Montserrat', sans-serif;
}

.log-out {
    margin-right: 10px;
}

.nav-link:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    color: red;
}

.logged-in > a {
    display: inline-block;
}

.logged-in > a:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

#places-link {
    margin-right: 20px;
}

#demo-button {
    padding: 5px;
    font-family: 'Open Sans', sans-serif;
    margin-right: 5px;
    margin-left: 10px;    
}

h1 {
    font-family: 'Oleo Script', cursive;
    font-size: 50px;
    margin: 0;
  }

  .register-login {
      display: flex;
      align-items: center;
  }

  .not-logged-in > a {
      margin: 10px;
  }

  @media screen and (max-width: 415px) {
    #demo-button {
        margin-top: 10px;
    }

    .logged-in {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }
#landing-hero {
    background: url(/static/media/restaurant-red-blur.b7e0ac1e.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 100%;
}

.landing_sections {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
}

.info {
    padding: 25px 50px;
    min-height: 375px;
    background: rgb(22, 40, 89);
    width: 100%;
    justify-content: center;
}

.white {
    background: white;
    display: flex;
    flex-direction: column;
}

.white > p {
    color: rgb(192, 14, 5);
}

.white-title {
    color: white;
}

.blue-title {
    color: rgb(1, 17, 58);
}

.red-title {
    color: rgb(192, 14, 5);
}

.red {
    background: rgb(192, 14, 5);
    display: flex;
    flex-direction: column;
}

.blue {
    background: url(/static/media/navy.42ad0663.jpg) no-repeat center center fixed;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.blue > p {
    color: white;
}

.red > p {
    color: rgb(1, 17, 58);
}

.info > h3 {
    font-size: 45px;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.info > p {
    font-size: 22px;
    max-width: 500px;
    font-family: 'Montserrat', sans-serif;
}

.tagline {
    font-family: 'Oleo Script', cursive;
    font-size: 110px;
    margin-bottom: 0px;
    line-height: 1.2;
    color: white;
}

.sub-tagline {
    font-family: 'Oleo Script', cursive;
    font-size: 40px;
    margin-top: 0px;
    color: rgb(1, 17, 58);
    max-width: 275px;
    text-align: center;
    background: white;
    border-radius: 10px;
}

.landing-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0px;
    padding-left: 30px;
    height: 350px;
    margin: 80px 20px;
}

.not-logged-in {
    display: flex;
    flex-direction: row;
}

.demo {
    align-items: center;
}

.demo > p {
    font-size: 16px;
    text-align: center;
}

.credentials {
    list-style: none;
    font-weight: 600;
    color: rgb(1, 17, 58);
}

@media screen and (max-width: 800px) {
    .tagline {
        font-size: 80px;
    }
    
    .landing_sections {
        display: flex;
        flex-direction: column;
    }

    .info {
        height: auto;
        height: initial;
    }

    .landing-header {
        margin-top: 50px;
    }

    .info > p {
        font-size: 24px;
    }

    h3 {
        font-size: 30px;
    }
}

@media screen and (max-width: 415px) {
    .Header {
        flex-direction: column;
        height: 200px;
        justify-content: center;
    }

    .tagline {
        font-size: 60px;
    }

    .sub-tagline {
        font-size: 30px;
    }

    .justify-start {
        margin-left: 0;
        margin-left: initial;
        margin-right: 0;
        margin-right: initial;
    }

    .not-logged-in {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .not-logged-in > a {
        margin: 0;
    }

    .info > h3 {
        font-size: 30px;
    }

    .info > p {
        font-size: 16px;
    }
}


.register-button {
    width: 100px;
    height: 25px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    background: white;
    border: 1px solid white;
    font-size: 16px;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: rgb(1, 17, 58);
    -webkit-transition-property: background, color;
    transition-property: background, color;
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
}

.register-button:hover {
    background: rgb(1, 17, 58);
    color: white;
}

.username, .password {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    border-radius: 5px;
}
* {
    box-sizing: border-box;
  }

html {
  height: 100%;
  background: url(/static/media/orange-red.7f5e7b05.jpg) no-repeat center center fixed;
}

body {
  height: inherit;
  font-size: 20px;
  /* color: white; */
  line-height: 1.5;
  background-size: cover;
}

#root {
  height: inherit;
}

.App {
  height: inherit;
}

.App_main {
  height: inherit;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

label {
  color: white;
  margin-right: 5px;
}  

input, textarea {
  color: black;
}

.go-back {
  color: white;
  width: 75px;
  background: rgb(1, 17, 58);
  font-size: 22px;
  text-align: center;
  margin: 10px auto;
  border: 1px solid white;
  border-radius: 5px;
  display: block;
  -webkit-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
}

.go-back:hover {
  color: rgb(1, 17, 58);
  background: white;
}

@media screen and (max-width: 415px) {
  .App_main {
    margin-top: 180px;
  }
}
label {
    color: white;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(1, 17, 58);
    height: 300px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    border: 2px solid white;
    margin: 0 auto;
}

.login-header {
    font-family: 'Oleo Script', cursive;
    font-size: 40px;
    text-align: center;
    color: white;
}

.invalid-login {
    color: white;
    text-align: center;
}
.place-name {
    font-size: 32px;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}

.place-name:hover {
    font-size: 34px;
    color: rgb(192, 14, 5);
}

.place-type {
    font-size: 18px;
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    margin: 10px 0px;
    color: white;
}

.place-section {
    border: 2px solid white;
    border-radius: 10px;
    max-width: 600px;
    width: 45%;
    min-width: 230px;
    background: rgb(1, 17, 58);
    font-family: 'Montserrat', sans-serif;
    margin: 40px auto 10px auto;
}

.place-controls {
    width: 80px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

#items-ordered {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.item-header {
    margin-bottom: 5px;
    text-decoration: underline;
}

.items-list {
    margin: 0;
    margin-bottom: 15px;
    padding-left: 0;
}

.items-list > li {
    list-style: none;
    font-size: 16px;
}

section {
    padding: 15px;
}

#notes {
    width: 80%;
    margin: 10px auto;
    color: white;
}

.notes-container {
    margin: 20px 0;
}

.notes-title {
    margin-bottom: 5px;
}

.notes-content {
    margin: 0;
    font-size: 16px;
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px;
}

button:hover {
   cursor: pointer;
}

.edit-delete {
    width: 75px;
    height: 25px;
    border-radius: 5px;
    text-align: center;
    background: white;
    border: 1px solid white;
    margin: 5px;
    font-size: 16px;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: rgb(1, 17, 58);
    margin: 5px auto;
    -webkit-transition-property: background, color;
    transition-property: background, color;
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
}

.edit-delete:hover {
    background: rgb(1, 17, 58);
    color: white;
}

.delete-button {
    background: none;
    border: none;
}

.edit-icon {
    color: white;
    font-size: 22px;
}

.delete-icon {
    color: white;
    font-size: 22px;
}

.edit-icon:hover, .delete-icon:hover {
    color: red;
}

.hh {
    margin: 0px;
}

#hh-box {
    margin-bottom: 15px;
    color: white;
}

#list-controls {
    margin: 5% auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 425px;
    border-radius: 15px;
}

#type-control, #happy-hour-control {
    padding: 10px;
    font-family: 'Oleo Script', cursive;
}

.control-label {
    color: white;
    font-size: 24px;
}

@media screen and (max-width: 415px) {
    #list-controls {
        flex-direction: column;
        width: auto;
        width: initial;
    }
}
#places-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-place-button {
    border: 2px solid white;
    padding: 5px;
    background: rgb(1, 17, 58);
    width: 110px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    display: block;
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
}

.add-place-button:hover {
    width: 115px;
}

.places-list-page {
    height: inherit;
    width: 100%;
    background-size: cover;
}

#items-ordered, .place-section {
    text-align: center;
}

.notes {
    width: 100%;
}

.place-notes {
    width: 100%;
    border-radius: 7px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.add-place {
    font-family: 'Montserrat', sans-serif;
    background: rgb(1, 17, 58);
    padding: 30px;
    border-radius: 10px;
}

.add-place-title {
    font-family: 'Oleo Script', cursive;
    font-size: 40px;
    color: white;
}

.add-place-header {
    padding: 0px;
}

.add-place-form {
    display: flex;
    flex-direction: column;
}

.save-place {
    align-self: center;
    margin-top: 20px;
    color: rgb(1, 17, 58);
    width: 75px;
    height: 25px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    background: white;
    -webkit-transition-property: background, color;
    transition-property: background, color;
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
}

.save-place:hover {
    background: rgb(1, 17, 58);
    color: white;
}

.place_name {
    height: 30px;
    font-size: 18px;
    border-radius: 7px;
    font-family: 'Montserrat', sans-serif;
}

.add-item {
    width: 90px;
}

label {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.items {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.item {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

@media screen and (max-width: 400px) {
    .add-place {
        max-width: 350px;
    }
}
.save-item {
    color: black;
    margin-left: 5px;
}

.item_name {
    font-family: 'Montserrat', sans-serif;
}
.remove-item {
    /* color: black; */
    margin-left: 5px;
    background: none;
    border: none;
}

.remove-item-icon {
    color: white;
    font-size: 16px;
}
.add-item {
    color: black;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url('https://fonts.googleapis.com/css?family=Fauna+One|Playfair+Display:900i&display=swap'); */

/* @import url('https://fonts.googleapis.com/css?family=Oleo+Script|Judson:400,700&display=swap'); */
