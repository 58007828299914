#list-controls {
    margin: 5% auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 425px;
    border-radius: 15px;
}

#type-control, #happy-hour-control {
    padding: 10px;
    font-family: 'Oleo Script', cursive;
}

.control-label {
    color: white;
    font-size: 24px;
}

@media screen and (max-width: 415px) {
    #list-controls {
        flex-direction: column;
        width: initial;
    }
}