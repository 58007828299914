#places-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-place-button {
    border: 2px solid white;
    padding: 5px;
    background: rgb(1, 17, 58);
    width: 110px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: all .1s ease;
}

.add-place-button:hover {
    width: 115px;
}

.places-list-page {
    height: inherit;
    width: 100%;
    background-size: cover;
}

#items-ordered, .place-section {
    text-align: center;
}
